import { Box, Typography, Button, Card, Grid, Stack } from '@mui/joy';
import { useTheme, useMediaQuery } from '@mui/material';
import BgImg from '../images/bg.jpg';
import { FaCamera, FaTruck, FaPiggyBank, FaCreditCard, FaPlane, FaWallet } from 'react-icons/fa';
import ProductCard from './ProductCard';
import Header from "./Header";
import Footer from "./Footer";

import { products } from './catalog/catalog';

const monthsInRussian = [
    'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
    'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
];

function getNextMonthWithDay() {
    const today = new Date();
    const currentDay = today.getDate();
    const currentYear = today.getFullYear();
    const currentMonthIndex = today.getMonth();
    const nextMonthIndex = (currentMonthIndex + 1) % 12;
    return `${currentDay} ${monthsInRussian[nextMonthIndex]} ${currentYear}`;
}

function scrollToElementById(id) {
    const element = document.getElementById(id);
    if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offset = 10;
        const scrollPosition = window.scrollY + elementPosition - offset;

        window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
        });
    }
}

export default function Main() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Header />
            <main>
                <section id='main'
                    style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        top: 8,
                        width: '100%',
                        color: '#fff',
                    }}>
                    <img style={{
                        position: 'absolute',
                        opacity: 0.85,
                        top: 20,
                        left: 0,
                        width: '100%',
                        maxHeight: '720px',
                        objectFit: 'cover',
                        zIndex: -10
                    }} src={BgImg} />
                    <Box style={{ position: 'relative', left: isMobile ? 0 : 200, top: 150, zIndex: 10 }}>
                        <Box sx={{ textAlign: isMobile ? 'center' : 'left' }}>
                            <Typography sx={{ mb: isMobile ? 0 : -2, fontSize: isMobile ? "40px" : '100px', color: 'black', fontWeight: 800 }}>
                                Распродажа
                            </Typography>
                            <Typography sx={{ mb: 1, maxWidth: '350px', fontSize: isMobile ? "30px" : '50px', lineHeight: 1.2, color: 'black', fontWeight: 500 }}>
                                фирменных видеорегистраторов со скидкой 50%
                            </Typography>
                            <Card variant='solid' sx={{ mb: 2, backgroundColor: 'white', p: 1 }}>
                                <Typography sx={{ fontSize: isMobile ? "14px" : '30px', color: 'black' }}>
                                    Акция действует до {getNextMonthWithDay()} года
                                </Typography>
                            </Card>
                            <Button onClick={() => scrollToElementById("catalog")} variant="outlined" size="lg" sx={{ fontSize: 22, paddingX: 4, paddingY: 2, borderRadius: 250 }}>
                                Смотреть каталог
                            </Button>
                        </Box>
                    </Box>
                </section>
                <selection id='price'>
                    <Box sx={{ padding: isMobile ? '20px' : '50px', marginTop: 20, paddingTop: 12 }}>
                        <Typography
                            level="h2"
                            sx={{ textAlign: 'center', fontSize: isMobile ? '24px' : '36px', fontWeight: 'bold', mb: 2 }}
                        >
                            ПОЧЕМУ ТАК ДЕШЕВО?
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: isMobile ? '14px' : '18px',
                                mb: 4,
                                maxWidth: '800px',
                                margin: '0 auto',
                            }}
                        >
                            Теперь вам не нужно искать оригинальный видеорегистратор по десяткам мелких магазинов,
                            мы собрали все лучшие предложения в одном месте! <br />
                            Приобрести крутейшие видеорегистраторы со скидкой до 50% от цены в обычном магазине - абсолютно реально!
                            И для этого не нужно ждать заказ месяцами из-за границы или покупать подделку.
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: isMobile ? '14px' : '18px',
                                mb: 4,
                                maxWidth: '800px',
                                margin: '0 auto',
                            }}
                        >
                            Вы спросите как такое возможно? Всё просто:
                        </Typography>
                        <Box sx={{ mt: 6 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: isMobile ? 'column' : 'row',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    gap: isMobile ? 3 : 5,
                                    maxWidth: '1200px',
                                    margin: '0 auto',
                                }}
                            >
                                <Box sx={{ maxWidth: '300px' }}>
                                    <FaCamera size={isMobile ? 40 : 60} />
                                    <Typography sx={{ mt: 2, fontSize: isMobile ? '12px' : '16px' }}>
                                        Мы сотрудничаем с большинством производителей видеорегистраторов напрямую без посредников - поэтому закупаем коллекции прошлого сезона с максимальными скидками
                                    </Typography>
                                </Box>
                                <Box sx={{ maxWidth: '300px' }}>
                                    <FaTruck size={isMobile ? 40 : 60} />
                                    <Typography sx={{ mt: 2, fontSize: isMobile ? '12px' : '16px' }}>
                                        Благодаря грамотной логистике значительно экономим на доставке конечному потребителю
                                    </Typography>
                                </Box>
                                <Box sx={{ maxWidth: '300px' }}>
                                    <FaPiggyBank size={isMobile ? 40 : 60} />
                                    <Typography sx={{ mt: 2, fontSize: isMobile ? '12px' : '16px' }}>
                                        Продажа через интернет магазин позволяет сократить затраты на аренду и зарплаты продавцов
                                    </Typography>
                                </Box>
                                <Box sx={{ maxWidth: '300px' }}>
                                    <FaCreditCard size={isMobile ? 40 : 60} />
                                    <Typography sx={{ mt: 2, fontSize: isMobile ? '12px' : '16px' }}>
                                        Мы рассчитываем на долгосрочные отношения с клиентами, поэтому делая минимальную наценку уверены - вы вернётесь к нам снова
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </selection>
                <selection id='catalog'>
                    <Box sx={{ padding: isMobile ? '20px' : '50px' }}>
                        <Typography
                            level="h2"
                            sx={{ textAlign: 'center', fontSize: isMobile ? '24px' : '36px', fontWeight: 'bold', mb: 2 }}
                        >
                            КАТАЛОГ
                        </Typography>
                        <Grid container spacing={2}>
                            {products.map(product => (
                                <Grid xs={12} sm={6} md={4} lg={3} key={product.id}>
                                    <ProductCard product={product} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </selection>
                <selection id='delivery'>
                    <div class="delivery-box" style={{ padding: isMobile ? '20px' : '50px' }}>
                        <Typography
                            level="h2"
                            sx={{ textAlign: 'center', fontSize: isMobile ? '24px' : '36px', fontWeight: 'bold', mb: 2 }}
                        >
                            ДОСТАВКА И ОПЛАТА
                        </Typography>
                        <div>
                            <div class="info">
                                <div className='Image'>
                                    <FaPlane className='Icon' />
                                </div>
                                <div>
                                    <strong>Доставка</strong>
                                    <p>БЫСТРАЯ ДОСТАВКА В ЛЮБУЮ ТОЧКУ БЕЛАРУСИ</p>
                                </div>
                            </div>
                            <div class="info">
                                <div className='Image'>
                                    <FaWallet className='Icon' />
                                </div>

                                <div>
                                    <strong>Никаких предоплат</strong>
                                    <p>ВЫ ОПЛАЧИВАЕТЕ ТОВАР ТОЛЬКО ПО ФАКТУ ПОЛУЧЕНИЯ НА РУКИ</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </selection>
                <selection id="reviews">
                    <div style={{ padding: isMobile ? '20px' : '50px' }}>
                        <Typography
                            level="h2"
                            sx={{ textAlign: 'center', fontSize: isMobile ? '24px' : '36px', fontWeight: 'bold', mb: 2 }}
                        >
                            ОТЗЫВЫ
                        </Typography>
                        <div class="reviews-container">
                            <div class="review">
                                <div class="review-content">
                                    <p class="review-text">"Отличный видеорегистратор! Картинка четкая, а функции просто супер. Очень доволен покупкой!"</p>
                                    <p class="review-author">— Алексей Смирнов</p>
                                </div>
                            </div>
                            <div class="review">
                                <div class="review-content">
                                    <p class="review-text">"Превосходный сервис и быстрая доставка. Видеорегистратор полностью оправдал ожидания. Рекомендую!"</p>
                                    <p class="review-author">— Наталья Рудько</p>
                                </div>
                            </div>
                            <div class="review">
                                <div class="review-content">
                                    <p class="review-text">"Очень доволен качеством записи и простотой установки. Теперь в дороге спокойно, благодаря этому регистратору."</p>
                                    <p class="review-author">— Дмитрий Федоров</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </selection>
            </main>
            <Footer />
        </>
    )
}