import axios from 'axios';

class OrderService {
    constructor() {
        this.apiToken = 'ZCWJcIuKQY1ZGEX06y5pLPTYsI1zeHqzVPpTjawprVKcSu3jsc4B9U6Bvr6n';
        this.baseUrl = 'https://supersale.pro/api/api/orders';
    }

    async getUserIp() {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            return response.data.ip;
        } catch (error) {
            //console.error('Error fetching user IP address:', error);
            throw error;
        }
    }

    async createOrder(userPhone, userName, productId, productPrice) {
        const userIp = await this.getUserIp();

        const headers = {
            'Content-Type': 'application/json',
            'X-Forwarded-For': userIp
        };

        const body = {
            api_token: this.apiToken,
            order: {
                phone: userPhone,
                name: userName,
                link: window.location.hostname,
                delivery_type_id: 3,
                sender_id: 1,
                products: [
                    {
                        offer_id: productId,
                        price: productPrice,
                        quantity: 1
                    }
                ]
            }
        };

        try {
            const response = await axios.post(this.baseUrl, body, { headers });
            return response.data;
        } catch (error) {
            //console.error('Error creating order:', error);
            throw error;
        }
    }
}

export default new OrderService();
